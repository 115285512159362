import React, { FC, PropsWithChildren } from 'react'

import { Wrapper } from './CardWrapper.styles'

type Props = {
  background?: string
}

export const CardWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  background,
}) =>
  background?.length ? (
    <Wrapper background={background}>{children}</Wrapper>
  ) : (
    <>{children}</>
  )
