import { Col } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

import {
  marginBreakpoints,
  hideBlockAt,
  columnVerticalAlign,
  columnHorizontalAlign,
} from '../../../../utils/commonAttrs'

export const Column = styled(Col)<{
  marginBottom: number
  flex: boolean
  verticalAlign: string
  centered: boolean
  columnContentAlign: string
  hiddenAt: string
}>`
  ${props => marginBreakpoints(props, 0)}
  ${({ flex }) =>
    flex
      ? css`
          display: flex;

          & > div {
            flex: 1;
          }
        `
      : ''}
  ${({ verticalAlign }) => columnVerticalAlign(verticalAlign)}

  /*
    TODO: after WP database migration of attributes 'centered' to 'columnContentAlign' is complete, remove check and cleanup all occurances of 'centered'.
  */
  ${({ centered, columnContentAlign }) =>
    centered
      ? css`
          text-align: center;
          justify-content: center;
        `
      : columnHorizontalAlign(columnContentAlign)}

  ${props => hideBlockAt(props)}

  .container {
    padding-right: 0;
    padding-left: 0;
  }
`
