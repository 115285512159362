import styled from 'styled-components'

import { spacing } from '../../../../utils/ui/spacing'

export const Wrapper = styled.div<{ background: string }>`
  width: 100%;
  border-radius: 4px;
  padding: ${spacing('xl')};
  background: ${({ background }) => background};
`
