import React, { FC, PropsWithChildren } from 'react'

import AnimateOnScroll from '../../../common/AnimateOnScroll'
import { Column } from './BootstrapColumn.styles'
import { CardWrapper } from './CardWrapper'

export type Props = {
  id?: string
  bsWidth: string
  centered?: boolean
  marginBottom?: string
  hiddenAt?: string
  verticalAlign?: string
  columnContentAlign?: string
  animateOnScroll?: boolean
  animationType?: string
  animationDelay?: string
  animationEasing?: string
  className?: string
  cardWrapperBackground?: string
}

export const BootstrapColumn: FC<PropsWithChildren<Props>> = ({
  id,
  children,
  bsWidth,
  centered,
  marginBottom,
  hiddenAt,
  verticalAlign,
  columnContentAlign,
  animateOnScroll,
  animationType,
  animationDelay,
  animationEasing,
  className,
  cardWrapperBackground,
}) => {
  const { xs, ...otherBreakpoints } = JSON.parse(bsWidth)

  return (
    <Column
      id={id}
      className={className}
      col={xs}
      {...otherBreakpoints}
      verticalAlign={verticalAlign}
      centered={centered}
      columnContentAlign={columnContentAlign}
      hiddenAt={hiddenAt}
      flex={Array.isArray(children) ? children.length === 1 : false}
      marginBottom={marginBottom}
    >
      <CardWrapper background={cardWrapperBackground}>
        {animateOnScroll ? (
          <AnimateOnScroll
            animationType={animationType}
            animationDelay={animationDelay}
            animationEasing={animationEasing}
          >
            {children}
          </AnimateOnScroll>
        ) : (
          children
        )}
      </CardWrapper>
    </Column>
  )
}
